/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f7fa;
  }
  
  /* Wallet Manager Container */
  .wallet-manager {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
  }
  
  /* Page Title */
  .wallet-manager h1 {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  /* Card Design */
  .card {
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .card h2 {
    color: #333;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  /* Input Fields */
  textarea,
  input,
  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    outline: none;
  }
  
  textarea {
    height: 100px;
    resize: none;
  }
  
  /* Buttons */
  button {
    /*width: 100%;*/
    background: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  button:hover {
    background: #388e3c;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .wallet-manager {
      padding: 10px;
    }
  
    .card {
      padding: 15px;
    }
  
    button {
      padding: 8px;
    }
  }
  