
/* Welcome page styling */
.welcome-page {
  background-color: #f8f9fa; /* Light background color */
  text-align: center;
  padding: 20px;
}

/* Card styling with radial gradient background and shadow */
.welcome-card {
  /* background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%); */
  border-radius: 15px;
  max-width: 500px;
  width: 100%;
  color: #2e2424;
}

.welcome-card h1 {
  color: #0f0e0e; /* White text color for the main heading */
  font-size: 1.5rem;
}

.welcome-card p {
  color: #f1f1f1; /* Light text color */
  font-size: 1.2rem;
}

.welcome-card .btn {
  background-color: #007bff; /* Primary button color */
  border-color: #007bff;
  font-size: 1.1rem;
  padding: 10px 20px;
}

.welcome-card .btn:hover {
  background-color: #0056b3; /* Darken button color on hover */
}

.shadow-lg {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}