/* Custom CSS for Mnemonic Entry */
.form-label {
    width: auto;
    margin-right: 8px;
}

.form-control {
    flex: 1;
}

.d-flex {
    display: flex !important;
}

.align-items-center {
    align-items: center !important;
}
/* Adjust the close button position if needed */
.btn-closea {
    padding: 0.25rem !important;
    background: #ef4848;
    border: none;
    color: #fff;
    /* font-size: 1rem; */
    cursor: pointer;
    right: 11px !important;
    font-size: 10px;
    border-radius: 9px;
    width: 20px !important;
}